import moment from 'moment';
import React from 'react';

import StyleGuide from '../StyleGuide';
import CalendarControl from '../CalendarControl';
import AlarmReport from './AlarmReport';
import AuditReport from './AuditReport';
import GreaseReport from './GreaseReport';
import MinMaxReport from './MinMaxReport';
import MinMaxAvgReport from './MinMaxAvgReport';
import ServiceReport from './ServiceReport';


export default class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportType: null,
      deviceId: null,
      startDate: moment().date(1).format('YYYY-MM-DD'),
      endDate: moment().add(1, 'month').date(1).format('YYYY-MM-DD')
    };
  }

  onChange(v, e) {
    this.setState({ [v]: typeof e === 'string' ? e : e.target.value });
  }

  generate() {
    const {rs} = this.props;
    const {reportType, deviceId, startDate, endDate} = this.state;
    rs.performStateOperation('getReport', reportType, deviceId, startDate, endDate);
  }

  renderReport({reportType, report}) {
    switch(reportType) {
      case 'alarm':
        return <AlarmReport rows={report} />;
      case 'audit':
        return <AuditReport rows={report} />;
      case 'batteryVoltage':
      case 'solarVoltage':
      case 'temperature':
        return <MinMaxAvgReport rows={report} />;
      case 'pressure1':
      case 'pressure2':
        return <MinMaxReport rows={report} />;
      case 'grease':
        return <GreaseReport rows={report} />;
      case 'service':
        return <ServiceReport rows={report} />;
      default:
        return <div className='alert alert-danger'>Unknow report {reportType}.</div>;
    }
  }

  render() {
    const {rs, state} = this.props;
    const {devices, report} = state;
    const startDate = new Date(this.state.startDate);
    const endDate = new Date(this.state.endDate);
    const generateEnabled = (
      this.state.reportType !== null &&
      this.state.reportType !== '' &&
      !isNaN(startDate) &&
      !isNaN(endDate) &&
      endDate > startDate
    );
    return (
      <StyleGuide.Layout rs={rs} state={state}>
        <div className='reports-page container p-3'>
          <div className='row form-group'>
            <label className='col-2 col-form-label' htmlFor='reportType'>Report type</label>
            <div className='col-4'>
              <select
                className='form-control'
                id='reportType'
                onChange={this.onChange.bind(this, 'reportType')}
              >
                <option value=''>Report type...</option>
                <option value='alarm'>Alarm report</option>
                <option value='audit'>Audit report</option>
                <option value='batteryVoltage'>Battery voltage report</option>
                <option value='grease'>Grease report</option>
                <option value='pressure1'>Pressure1 report</option>
                <option value='pressure2'>Pressure2 report</option>
                <option value='service'>Service report</option>
                <option value='solarVoltage'>Solar voltage report</option>
                <option value='temperature'>Temperature report</option>
              </select>
            </div>
          </div>
          {this.state.reportType && this.state.reportType !== 'audit' && (
            <div className='row form-group'>
              <label className='col-2 col-form-label' htmlFor='deviceId'>Device</label>
              <div className='col-4'>
                <select
                  className='form-control'
                  id='deviceId'
                  onChange={this.onChange.bind(this, 'deviceId')}
                >
                  <option value=''>Device...</option>
                  {
                    devices.map((d, i) =>
                      (<option key={i} value={d.deviceId}>{d.name || d.deviceIdShort}</option>)
                    )
                  }
                </select>
              </div>
            </div>
          )}
          <div className='row form-group'>
            <label className='col-2 col-form-label'>Date range</label>
            <div className='col-4'>
              <div className='input-group'>
                <CalendarControl
                  date={this.state.startDate}
                  onSelect={this.onChange.bind(this, 'startDate')}
                />
                <CalendarControl
                  date={this.state.endDate}
                  onSelect={this.onChange.bind(this, 'endDate')}
                />
              </div>
            </div>
          </div>
          <div className='row form-group mb-4'>
            <div className='col'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={this.generate.bind(this)}
                disabled={generateEnabled !== true}
              >Generate</button>
            </div>
          </div>
          {
            report ? this.renderReport(report) : null
          }
        </div>
      </StyleGuide.Layout>
    );
  }
}
